<template>
  <div>
    <b-form-checkbox
      value="1"
      unchecked-value="2"
      v-model="form.allocated"
      switch
      class="table-status-icon"
      @change.native="changeStatus"
    ></b-form-checkbox>

    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalS' + params.data.advance_id"
        :button-close-hidden="true"
        class="holamundo p-2"
        title="Allocated Status"
        :active.sync="popupActive"
      >
        <div class="text-center">
          <h5>Do you want to change Status ?</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus" -->
              <vs-button
                color="success"
                type="filled"
                @click="onChangeCheckbox()"
                class="mr-3"
                >Yes</vs-button
              >

              <vs-button
                color="danger"
                type="filled"
                @click.native="privStatus()"
                >No</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
  <!--  -->
</template>

<script>
import Vue from "vue";
import CashManagementService from "@/services/CashManagementService.js";

export default Vue.extend({
  data() {
    return {
      params: null,
      popupActive: false,
      form: {
        allocated: "1",
      },
      copy_allocated: null,
    };
  },
  mounted() {
    this.form = this.params.data;
    this.copy_allocated = this.form.allocated;
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalS" + this.params.data.advance_id
    );
    console.log("_id", _id);
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    changeStatus() {
      this.popupActive = true;
    },
    onChangeCheckbox: function () {
      this.$vs.loading();
      // this.value = this.value === "1" ? "2" : "1";
      let id = this.params.data.advance_id;
      let payload = {
        advance_id: this.params.data.advance_id,
        allocated: this.form.allocated,
      };

      CashManagementService.updateCashRequest(payload)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status == true || data.Status == "true") {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,

              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            this.popupActive = false;
          } else {
            this.$vs.loading.close();
            console.log("data :", data);
            this.$vs.notify({
              title: "Error!",
              text: data.Message,

              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
          setTimeout(() => {
            eventBus.$emit("refreshTableCashList", data);
          }, 1);
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
    privStatus: function () {
      this.popupActive = false;
      this.form.allocated = this.copy_allocated;
    },
  },
});
</script>
